'use client';

import {
  ButtonVariant,
  PopupMenu,
  Spinner,
  SpinnerTheme,
} from '@unique/component-library';
import { IconSelect, IconMeatballsMenu } from '@unique/icons';
import { FC } from 'react';

type ChatMenuHeaderProps = {
  title?: string;
  isLoading?: boolean;
  onSelectAll: () => void;
};

export const ChatMenuHeader: FC<ChatMenuHeaderProps> = ({ 
  title = 'Chats', 
  isLoading = false,
  onSelectAll,
}) => {
  
  return (
    <div className="subtitle-1 flex items-center justify-between pb-4 pl-4 pr-2 pt-4">
      <div className="flex">
        {title}
        {isLoading && (
          <Spinner
            theme={SpinnerTheme.LIGHT}
            wrapperClasses="ml-4 flex items-center"
            size={16}
          />
        )}
      </div>
      {!isLoading && (
        <div className="hidden sm:flex">
          <PopupMenu
            buttonVariant={ButtonVariant.SHAPE}
            buttonIcon={<IconMeatballsMenu />}
            menuItems={[
              {
                label: 'Select all',
                icon: <IconSelect width="14px" height="14px" />,
                onClick: () => {
                  onSelectAll();
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  
  );
};
