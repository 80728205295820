import { MessageCreateMutationVariables, Role } from '@/@generated/graphql';
import useChatStream from '@/hooks/useChatStream';
import { useAppSelector } from '@/store';
import { ButtonIcon, FileTypeIcon, LoadingText, ProgressBar } from '@unique/component-library';
import { IconExpiredFile, IconFileLoading } from '@unique/icons';
import { loadFile } from '@unique/next-commons/helpers';
import { ClientContext, Service } from '@unique/next-commons/swr';
import {
  INGESTION_STATUS_TO_MESSAGE,
  isIngestingContent,
  ToastVariant,
  useToast,
} from '@unique/shared-library';
import { isNumber } from 'lodash';
import { useParams } from 'react-router-dom';
import { FC, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useContentByChatQuery } from '@/lib/swr/hooks';
import { getFileTitleFromKey } from '@/helpers/getFileTitleFromKey';
import { isSystemMessage } from '@/helpers/messages';

type TranslationUploadedDocumentProps = {
  language: string;
  assistantId: string;
};

const REFRESH_INTERVAL_TRANSLATION = 10_000;

export const TranslationUploadedDocument: FC<TranslationUploadedDocumentProps> = ({
  language,
  assistantId,
}) => {
  const [translationProgress, setTranslationProgress] = useState(0);
  const [isTranslating, setIsTranslating] = useState(false);
  const [status, setStatus] = useState('');
  const [estimatedTimeToTranslateInSeconds, setEstimatedTimeToTranslateInSeconds] = useState(0);
  const { translateToLanguage } = useAppSelector(({ translation }) => translation);
  const auth = useAuth();
  const { services } = useContext(ClientContext);
  const { id } = useParams<{ id: string }>();
  const { showToast } = useToast();

  const { data: contentData } = useContentByChatQuery(
    id
      ? {
          chatId: id,
        }
      : null,
    { revalidateOnFocus: false, refreshInterval: isTranslating ? REFRESH_INTERVAL_TRANSLATION : 0 },
  );

  const content = contentData?.contentByChat?.length > 0 ? contentData?.contentByChat : [];
  const contentToShow = content[content.length - 1];
  const showExpirationInfo = isNumber(contentToShow.expiresInDays);
  const isExpired = showExpirationInfo && contentToShow.expiresInDays <= 0;

  const { handleChat, subscriptionData } = useChatStream({
    currentChatId: id,
    onError: () => {
      setIsTranslating(false);
      showToast({
        message: 'Failed to translate document. Please try again.',
        variant: ToastVariant.ERROR,
      });
    },
  });

  const isStreaming =
    subscriptionData &&
    subscriptionData.messageUpdate?.stoppedStreamingAt === null &&
    !isSystemMessage(subscriptionData.messageUpdate?.text) &&
    isTranslating;

  useEffect(() => {
    // 2 uploaded documents means that translated document arrived
    if (content.length < 2) return;
    setIsTranslating(false);
  }, [content]);

  useEffect(() => {
    if (!isStreaming) {
      setTranslationProgress(0);
    }
    const messageText = subscriptionData?.messageUpdate?.text;
    if (!messageText) return;

    setTranslationProgress((prev) => {
      if (prev > 100) return 100;
      // message text looks like this for document translation: <progress>50</progress>
      const includesProgressTag = messageText && messageText?.match(/<progress>(.*?)<\/progress>/);
      // if there is no progress tag, return prev value + 1 to show progress
      if (!includesProgressTag) return prev + 1;
      const [, progress] = includesProgressTag;
      const progressNumber = parseInt(progress);
      return typeof progressNumber === 'number' && progressNumber > prev ? progressNumber : prev;
    });

    setEstimatedTimeToTranslateInSeconds((prev) => {
      const includesEstimatedTimeTag =
        messageText && messageText?.match(/<estimated_time>(.*?)<\/estimated_time>/);
      if (!includesEstimatedTimeTag) return prev;
      const [, timeInSec] = includesEstimatedTimeTag;
      const timeInSecAsNumber = parseInt(timeInSec);
      return typeof timeInSecAsNumber === 'number' && timeInSecAsNumber > 0
        ? timeInSecAsNumber
        : prev;
    });

    setStatus((prev) => {
      const includesStatusTag = messageText && messageText?.match(/<status>(.*?)<\/status>/);
      if (!includesStatusTag) return prev;
      const [, status] = includesStatusTag;
      return status;
    });
  }, [subscriptionData?.messageUpdate?.text, isStreaming]);

  if (!contentToShow) return null;

  if (isIngestingContent(contentToShow.ingestionState)) {
    return (
      <div className="flex w-full flex-col justify-center gap-2">
        <div className="flex items-center justify-center">
          <IconFileLoading height="56px" width="56px" />
        </div>
        <p className="text-on-surface mt-4 text-center font-bold">
          <LoadingText>{INGESTION_STATUS_TO_MESSAGE[contentToShow.ingestionState]}</LoadingText>
        </p>
      </div>
    );
  }

  const formatTimeToTranslate = (timeInSeconds: number) => {
    if (timeInSeconds < 60) {
      return `${timeInSeconds}s`;
    }
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  if (isStreaming) {
    return (
      <div className="flex w-full flex-col items-center justify-center gap-2">
        <div className="flex items-center justify-center">
          <IconFileLoading height="56px" width="56px" />
        </div>
        <p className="text-on-surface mt-4 text-center font-bold">
          <LoadingText>{status || 'Is Translating'}</LoadingText>
        </p>
        {estimatedTimeToTranslateInSeconds > 0 && (
          <p className="body-2 text-on-background-dimmed">
            Estimated time: {formatTimeToTranslate(estimatedTimeToTranslateInSeconds)}
          </p>
        )}
        <div className="my-4 w-full max-w-[250px]">
          <ProgressBar
            completed={translationProgress}
            className="bg-control border-control border"
          />
        </div>
      </div>
    );
  }

  const handleClickDownload = async () => {
    try {
      await loadFile({
        accessToken: auth.user.access_token,
        ingestionUrl: services[Service.NODE_INGESTION],
        content: contentToShow,
        chatId: id,
        shouldOpen: true,
      });
    } catch {
      showToast({
        message: `Can not open file ${contentToShow.title || contentToShow.key}`,
        variant: ToastVariant.ERROR,
      });
    }
  };

  const handleTranslateDocument = () => {
    setIsTranslating(true);
    const payload: MessageCreateMutationVariables = {
      input: {
        text: `Translate ${content[0]?.id} to ${translateToLanguage.value}`,
        role: Role.User,
      },
      assistantId,
      chatId: id,
      translationInput: {
        translateToLanguage: translateToLanguage.value,
        contentIdToTranslate: content[0]?.id,
      },
    };
    handleChat(payload);
  };
  const hasTranslatedContent = content.length > 1;

  return (
    <>
      {isExpired ? (
        <IconExpiredFile width="56px" height="56px" />
      ) : (
        <FileTypeIcon dimensions="56px" mimeType={contentToShow.mimeType} />
      )}
      <div className="subtitle-1 text-on-background-main px-4 py-4 text-center font-bold">
        {getFileTitleFromKey(contentToShow.title) ??
          getFileTitleFromKey(contentToShow.key) ??
          'Untitled File'}
      </div>
      <div className="flex items-center gap-x-4">
        {!hasTranslatedContent ? (
          <ButtonIcon onClick={handleTranslateDocument}>Translate to {language}</ButtonIcon>
        ) : (
          <ButtonIcon onClick={handleClickDownload}>Download</ButtonIcon>
        )}
      </div>
    </>
  );
};
