'use client';
import { FC } from 'react';
import ChatContainer from './ChatContainer';
import { useParams } from 'react-router-dom';
import { useChatQuery } from '@/lib/swr/hooks';
import { hasTranslationUI } from '@unique/next-commons/helpers/hasTranslationUI';
import TranslationComponent from '../Translation/TranslationComponent';
import { Spinner } from '@unique/component-library';

export const ChatMessagesApp: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading: isLoadingChat } = useChatQuery(
    id
      ? {
          chatId: id,
        }
      : null,
  );

  const currentChat = data?.chat;

  if (isLoadingChat)
    return (
      <Spinner wrapperClasses="pt-[80px] absolute left-0 top-0 flex h-full w-full items-center justify-center" />
    );

  if (currentChat?.id && hasTranslationUI(currentChat?.assistant)) {
    return <TranslationComponent assistant={currentChat.assistant} currentChat={currentChat} />;
  }

  return (
    <div className="mx-auto flex h-[calc(100vh-119px)] flex-col px-4 sm:h-[calc(100vh-81px)]">
      <ChatContainer id={id} currentChat={currentChat} />
    </div>
  );
};
