import { Role } from '@/@generated/graphql';
import { useCopyToClipboard } from '@unique/shared-library';
import { MessageWithDebugInfo } from '@/lib/swr/types';
import { MarkdownPreview } from '@unique/component-library';
import { IconCaretUp } from '@unique/icons';
import cn from 'classnames';
import { FC, useState } from 'react';

export const ChatMessageDebugFooter: FC<{
  message: MessageWithDebugInfo;
}> = ({ message }) => {
  const { copiedText, copy } = useCopyToClipboard();
  return (
    <div className="border-background-variant text-2xs -mx-3 mt-3 flex flex-wrap items-start border-t px-3 pt-2">
      {message.role === Role.User && !!message.gptRequest?.length && (
        <div className="group relative w-full transition">
          <DebugButton title="JSON Value">
            <MarkdownPreview
              text={'```json\n' + JSON.stringify(message.gptRequest, null, 2) + '\n```'}
              className="!bg-transparent"
              copyCode={copy}
              copiedCode={copiedText}
              wrapCode
            />
          </DebugButton>
        </div>
      )}

      {message.role === Role.User && message.debugInfo && (
        <div className="group relative w-full transition">
          <DebugButton title="Debug Info">
            <MarkdownPreview
              text={'```json\n' + JSON.stringify(message.debugInfo, null, 2) + '\n```'}
              className="!bg-transparent"
              copyCode={copy}
              copiedCode={copiedText}
              wrapCode
            />
          </DebugButton>
        </div>
      )}

      {message.role === Role.Assistant && (message.originalText || message.text) && (
        <DebugButton title="Original Response">
          <div>{message.originalText ?? message.text}</div>
        </DebugButton>
      )}

      {message.role === Role.Assistant && message.feedback && (
        <DebugButton title="Feedback">
          <div>positive: {message.feedback.positive ? 'yes' : 'no'}</div>
          <div>Text: {message.feedback.text}</div>
          {!message.feedback.positive && message?.feedback?.additionalInfo && (
            <div>
              Info:{' '}
              <MarkdownPreview
                text={
                  '```json\n' +
                  JSON.stringify(JSON.parse(message.feedback.additionalInfo), null, 2) +
                  '\n```'
                }
              />
            </div>
          )}
        </DebugButton>
      )}
    </div>
  );
};

const DebugButton: FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleListToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        data-testid="message-debug-footer"
        className="bg-surface hover:bg-surface flex w-full cursor-pointer items-center"
        onClick={handleListToggle}
      >
        {title}
        <span
          className={cn({
            'ml-1.5 origin-center transition': true,
            '-rotate-180 transform': !isOpen,
          })}
        >
          <IconCaretUp />
        </span>
      </button>
      <div
        className={cn({
          'event-list body-1': true,
          hidden: !isOpen,
        })}
      >
        {children}
      </div>
    </>
  );
};
