import { MarkdownPreview } from '@unique/component-library';
import { CustomCellRendererProps } from 'ag-grid-react';
import { Dispatch, SetStateAction, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';

export const DueDiligenceCellRenderer = (props: CustomCellRendererProps) => {
  const [references, setReferences] = useState(new Set<string>());

  const isNumberColumn = props.colDef.headerName === '';
  const isFirstRow = props.node.rowIndex === 0;
  return (
    <CellRenderer
      references={Array.from(references)}
      setReferences={setReferences}
      value={props.value}
      renderAsPlainText={isNumberColumn}
      isFirstRow={isFirstRow}
    />
  );
};

const CellRenderer = ({
  references,
  setReferences,
  value,
  renderAsPlainText,
  isFirstRow,
}: {
  references: string[];
  value: string;
  setReferences: Dispatch<SetStateAction<Set<string>>>;
  renderAsPlainText: boolean;
  isFirstRow: boolean;
}) => {
  const navigate = useNavigate();
  const { spaceSlug, spaceId } = useParams();

  const addLinkToPlaceholders = useCallback(
    (text: string) => {
      const regex = /\[\d+\]/g;
      const parts = text.split(regex);
      const matches = text.match(regex) || [];

      setReferences(new Set(matches));

      return parts
        .map((part, index) => {
          const match = matches[index] ? `<sup>${matches[index].slice(1, -1)}</sup>` : '';
          return `${part}${match}`;
        })
        .join('');
    },
    [setReferences],
  );

  const textRenderer = useMemo(() => {
    if (!value) return '';
    if (value.match(/\[\d+\]/g)) {
      return addLinkToPlaceholders(value);
    }
    return value;
  }, [addLinkToPlaceholders, value]);

  const handleReferenceClicked = useCallback(
    (reference: string) => {
      const id = reference.slice(1, -1);
      navigate(`/space/${spaceId}/${spaceSlug}?reference=${id}&requestId=${nanoid()}`);
    },
    [navigate, spaceId, spaceSlug],
  );

  if (renderAsPlainText) {
    return <div className="!font-normal">{textRenderer}</div>;
  }

  if (isFirstRow) {
    return <div className="px-2 leading-[38px]">{textRenderer}</div>;
  }

  return (
    <div className="flex flex-col gap-2">
      <MarkdownPreview text={textRenderer} />
      <div className="flex gap-1 px-1">
        {references.map((reference) => (
          <div
            key={reference}
            onClick={() => handleReferenceClicked(reference)}
            className="text-attention-variant cursor-pointer underline hover:no-underline"
          >
            {reference}
          </div>
        ))}
      </div>
    </div>
  );
};
