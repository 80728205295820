import {
  isInternalUniqueUrl,
  mapReadUrlForReferences,
  parseContentIdFromInternalUrl,
} from '@/helpers/references';
import { useContentByIdQuery } from '@/lib/swr/hooks';
import { Reference } from '@/lib/swr/types';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { FC } from 'react';
import ChatReferenceContent from './ChatReferenceContent';
import useChatPdfPreview from '@/hooks/useChatPdfPreview';

interface ChatReferencesProps {
  references?: Reference[];
  showPdfHighlighting?: boolean;
  redirectInternalStorageOnly?: boolean;
}

export const ChatReferences: FC<ChatReferencesProps> = ({
  references,
  showPdfHighlighting,
  redirectInternalStorageOnly,
}) => {
  const { id } = useParams<{ id: string }>();
  if (!references) references = [];

  const referencesToRewriteUrl = references.filter((reference) =>
    isInternalUniqueUrl(reference.url),
  );

  const { data: contentData } = useContentByIdQuery({
    contentIds: referencesToRewriteUrl.map((reference) =>
      parseContentIdFromInternalUrl(reference.url),
    ),
    chatId: id,
  });

  const className =
    'subtitle-2 px-2 py-2 flex items-center justify-center min-h-[40px] text-on-background-dimmed bg-background rounded-lg mr-5 last:ml-0 mt-4 relative';

  const sortedReferencesWithReadUrl = mapReadUrlForReferences(
    references,
    contentData,
    redirectInternalStorageOnly,
  ).sort((a, b) => a.sequenceNumber - b.sequenceNumber);

  const { handleClickReference, loadingReference } = useChatPdfPreview({
    chatId: id,
    showPdfHighlighting,
    redirectInternalStorageOnly,
  });

  return (
    <div>
      <div className="overline-text text-on-background-dimmed">References:</div>
      <div className="flex flex-wrap">
        {sortedReferencesWithReadUrl.map((reference) =>
          reference.url ? (
            <a
              onClick={(event) => {
                event.preventDefault();
                handleClickReference(reference);
              }}
              target="_blank"
              rel="noopener noreferrer"
              key={reference.id}
              className={cn({
                [className]: true,
                '!cursor-default opacity-50': reference.id === loadingReference,
                'hover:bg-background-variant hover:text-on-surface group max-w-[230px] flex-auto cursor-pointer transition-all':
                  true,
              })}
            >
              <ChatReferenceContent
                reference={reference}
                isLoading={reference.id === loadingReference}
                isInternalPdfReference={!!reference.pdfPreviewUrl || !!reference.pdfPreviewName}
                showPdfHighlighting={showPdfHighlighting}
              />
            </a>
          ) : (
            <div key={reference.id} className={className}>
              <ChatReferenceContent reference={reference} />
            </div>
          ),
        )}
      </div>
    </div>
  );
};
