import { AssistantQuery } from '@/@generated/graphql';
import { SpaceInfo } from '../Space/SpaceInfo';
import { ButtonIcon, InputField } from '@unique/component-library';
import { IconFileAdd } from '@unique/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getUseCreateDueDiligenceKey,
  useCreateDueDiligence,
  useDueDiligenceQuery,
} from '@/lib/swr/hooks';
import DueDiligenceList from './DueDiligenceList';
import { useContext, useEffect } from 'react';
import { LayoutContext } from '@unique/shared-library';
import { SpaceHeader } from '../Space/SpaceHeader';
import { updateDueDiligencePayload, useAppDispatch } from '@/store';

export default function DueDiligenceHome({
  assistant,
}: {
  assistant: AssistantQuery['assistantByUser'];
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: dueDiligenceList, isLoading } = useDueDiligenceQuery();
  const { setHeaderItems } = useContext(LayoutContext);
  const dispatch = useAppDispatch();

  const { trigger: createDueDiligence, isMutating: creatingDueDiligence } = useCreateDueDiligence(
    getUseCreateDueDiligenceKey(),
    {
      onSuccess: ({ createDueDiligence }) => {
        dispatch(updateDueDiligencePayload(null));
        navigate(
          location.pathname +
            `/create-worksheet?dueDiligenceId=${createDueDiligence.id}&chatId=${createDueDiligence.magicTableSheet.chatId}&sheetId=${createDueDiligence.magicTableSheet.id}&step=import-questions`,
        );
      },
    },
  );

  useEffect(() => {
    setHeaderItems([<SpaceHeader key={`space-header-${assistant}`} title={'Due Diligence'} />]);
    return () => {
      setHeaderItems([]);
    };
  }, []);

  return (
    <div>
      {assistant?.explanation || assistant?.alert ? (
        <div className="bg-background flex items-center justify-center py-5">
          <div className="mx-auto max-w-[928px] px-3 sm:px-0">
            <SpaceInfo
              explanation={assistant?.explanation}
              alert={assistant?.alert}
              bg="bg-surface"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="mx-auto mt-5 max-w-[928px] px-3 sm:px-0">
        <div className="bg-surface">
          <div className="flex w-full items-center justify-between gap-3 px-0 sm:px-2">
            <InputField name="search" placeholder="Search sheet" type="text" />
            <ButtonIcon
              icon={<IconFileAdd />}
              isLoading={creatingDueDiligence}
              className="bg-primary-cta"
              onClick={() => createDueDiligence({ assistantId: assistant?.id })}
            >
              New&nbsp;Sheet
            </ButtonIcon>
          </div>
          <div className="mt-10">
            <DueDiligenceList dueDiligence={dueDiligenceList?.dueDiligence} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}
