'use client';

import { useChatDelete } from '@/hooks/useChatDelete';
import { CHAT_QUERY_VARIABLES } from '@/lib/constants/chatQueryVariables';
import { useChatsQuery } from '@/lib/swr/hooks';
import { useAppSelector } from '@/store';
import {
  PopupMenu,
  SecondLevelNavItem,
} from '@unique/component-library';
import { IconSelect, IconDelete, IconEdit } from '@unique/icons';
import { useLocation, useParams } from 'react-router-dom';
import { FC, useEffect, useMemo, useState } from 'react';
import { useChatRename } from './ChatRenameProvider';
import IndeterminateCheckbox from '@unique/component-library/src/IndeterminateCheckbox';
import { ChatMenuHeader } from './ChatMenuHeader';
import { ChatMenuSelectionHeader } from './ChatMenuSelectionHeader';
import { Chat } from '@/@generated/graphql';

type ChatMenuProps = {
  title?: string;
};

export const ChatMenu: FC<ChatMenuProps> = ({ title = 'Chats' }) => {
  const { id } = useParams();
  const chatsToDelete = useAppSelector((state) => state.chat.chatsToDelete);
  const {
    data: chats,
    isLoading,
    error: chatError,
    mutate,
  } = useChatsQuery(CHAT_QUERY_VARIABLES, { revalidateOnFocus: false });
  const { deleteChat, deleteChatIds, isDeletingChat } = useChatDelete();
  const { handleClickRename } = useChatRename();
  const visibleChats = useMemo(() => {
    const chatIdsToDelete = chatsToDelete?.map((chat) => chat.chatId) ?? [];
    return chats?.chats?.filter((chat) => !chatIdsToDelete.includes(chat.id)) ?? [];
  }, [chats, chatsToDelete]);

  const [selectedChats, setSelectedChats] = useState<string[]>([]);

  const getIsOnSingleChatPage = (chatId: string) => id === chatId;

  const [isSelectingModeActive, setIsSelectingModeActive] = useState(false);

  const toggleSelectedChat = (chatId: string) => {
    if (selectedChats.includes(chatId)) {
      setSelectedChats((selectedChats) => selectedChats.filter((selectedChat) => selectedChat !== chatId));
      return;
    }
    setIsSelectingModeActive(true);
    setSelectedChats((selectedChats) => [...selectedChats, chatId]);
  };

  const toggleAllChats = () => {
    setIsSelectingModeActive(true);
    if (selectedChats.length != visibleChats.length) {
      setSelectedChats(visibleChats.map((chat) => chat.id));
      return;
    }
    setSelectedChats([]);
  };

  // If user navigate we reset select mode
  const location = useLocation();
  useEffect(() => {
    setIsSelectingModeActive(false);
    setSelectedChats([]);
  }, [location.pathname]);

  useEffect(() => {
    if (!id?.length || isLoading) return;
    // If a new chat is created (route changes with new id), revalidate chat menu
    const chatExists = chats?.chats.findIndex((chat) => chat.id === id) > -1;
    if (!chatExists) mutate();
  }, [chats?.chats, id, mutate, isLoading]);

  return (
    <div className="relative h-full">
      {!isSelectingModeActive ? (
        <ChatMenuHeader 
          title={title} 
          isLoading={isLoading} 
          onSelectAll={() => {
            toggleAllChats();
          }}/>
      ) : (
        <ChatMenuSelectionHeader 
          selectedChatIds={selectedChats}
          chats={visibleChats as Chat[]}
          onToggleButtonClick={toggleAllChats}
          onDeleteButtonClick={() => {
            deleteChatIds(selectedChats);
            setSelectedChats([]);
            setIsSelectingModeActive(false);
          }}
          onCancelButtonClick={() => {
            setSelectedChats([]);
            setIsSelectingModeActive(false);
          }}
          />
      )}

      {!isLoading && !visibleChats.length && !chatError && (
        <SecondLevelNavItem
          key="no-chats"
          name="Your past chats will show here"
          isActive={false}
          readOnly={true}
        />
      )}

      {!isLoading && !chatError && (
        <>
          {visibleChats.map((chat) => (
              <SecondLevelNavItem
                key={chat.id}
                name={chat.title ?? 'Unnamed chat'}
                href={`/${chat.id}`}
                isActive={getIsOnSingleChatPage(chat.id)}
                className="group"
                onClick={isSelectingModeActive && (() => toggleSelectedChat(chat.id))}
                icon={
                  isSelectingModeActive && (
                    <IndeterminateCheckbox defaultChecked={selectedChats.includes(chat.id)} />
                  )
                }
              >
                {!isSelectingModeActive && (
                  <div className="ml-auto">
                    <PopupMenu
                      buttonClassesClosed="hidden sm:group-hover:flex"
                      buttonClassesOpened="flex ml-2 sm:group-hover:ml-0"
                      menuItems={[
                        {
                          label: 'Select',
                          icon: <IconSelect width="14px" height="14px" />,
                          onClick: (event) => {
                            event.preventDefault();
                            toggleSelectedChat(chat.id);
                          },
                        },
                        {
                          label: 'Rename',
                          icon: <IconEdit width="18px" height="18px" />,
                          onClick: (event) => handleClickRename(event, chat),
                        },
                        {
                          label: 'Delete',
                          icon: <IconDelete />,
                          isLoading: isDeletingChat,
                          onClick: (event) => deleteChat(event, chat.id),
                        },
                      ]}
                    />
                  </div>
                )}
              </SecondLevelNavItem>
            ))}
        </>
      )}
    </div>
  );
};
