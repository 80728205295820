import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { FC, ReactNode } from 'react';

type Props = {
  onSubmit: () => void;
  icon: ReactNode;
  title: string;
  description: string;
  uploadButtonTitle: string;
  skipButton: ReactNode;
};

const DueDiligenceAddSource: FC<Props> = ({
  onSubmit,
  icon,
  description,
  title,
  uploadButtonTitle,
  skipButton,
}) => {
  return (
    <div className="mx-5 flex flex-col justify-center">
      <div className="mx-auto w-full max-w-3xl">
        <div className="border-control mx-auto mb-6 mt-10 flex min-h-[480px] w-full items-center justify-center rounded-md border-2 border-dashed">
          <div className="flex flex-col items-center gap-3">
            {icon}
            <p className="text-md text-on-background-main text-center font-bold text-white md:text-2xl">
              {title}
            </p>
            <p className="text-on-background-dimmed text-sm text-white">{description}</p>
            <ButtonIcon variant={ButtonVariant.PRIMARY} onClick={() => onSubmit()}>
              {uploadButtonTitle}
            </ButtonIcon>
          </div>
        </div>
        {skipButton}
      </div>
    </div>
  );
};

export default DueDiligenceAddSource;
