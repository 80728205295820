import { getInitials } from '@unique/component-library';
import { IconAssistant } from '@unique/icons';
import { useAuth } from 'react-oidc-context';

export default function SenderProfile({
  isUserMessage,
  name,
}: {
  isUserMessage: boolean;
  name?: string;
}) {
  const {
    user: { profile },
  } = useAuth();
  const initials = name ? getInitials(name) : getInitials(profile.name, profile.email);

  return (
    <>
      {isUserMessage ? (
        <div className="subtitle-2 label bg-background-variant text-on-background-main flex h-10 w-10 items-center justify-center rounded-full">
          {initials}
        </div>
      ) : (
        <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full">
          <IconAssistant />
        </div>
      )}
    </>
  );
}
