import { CHAT_QUERY_VARIABLES } from '@/lib/constants/chatQueryVariables';
import { getUseChatsQueryKey, useChatDeleteManyMutation } from '@/lib/swr/hooks';
import {
  ChatDeletionState,
  ChatToDelete,
  setChatsToDelete,
  useAppDispatch,
  useAppSelector,
} from '@/store';
import { ToastVariant, useToast } from '@unique/shared-library';
import { useParams, useNavigate } from 'react-router-dom';
import { MouseEvent, useEffect } from 'react';

export function useChatDelete() {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const chatsToDelete = useAppSelector((state) => state.chat.chatsToDelete) ?? [];

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // edge case: show a built-in browser confirmation dialog when the user tries to refresh the page while a chat is still in deleting state
      if (chatsToDelete.some((chat) => chat.deletionState === ChatDeletionState.DELETING)) {
        event.preventDefault();
      } else {
        dispatch(setChatsToDelete([]));
      }
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [chatsToDelete]);

  const { trigger: deleteChatManyMutation, isMutating: isDeletingChat } = useChatDeleteManyMutation(
    getUseChatsQueryKey(CHAT_QUERY_VARIABLES),
    {
      onSuccess: () => {
        showToast({
          message: 'Successfully deleted your chat.',
          variant: ToastVariant.SUCCESS,
        });
      },
    },
  );

  const triggerDeleteChatMutation = (chats: ChatToDelete[]) => {
    if (!chats?.length) return;

    deleteChatManyMutation(
      {
        chatIds: chats
          .filter((chat) => chat.deletionState === ChatDeletionState.DELETING)
          .map((chat) => chat.chatId),
      },
      {
        revalidate: false,
        throwOnError: false,
      },
    );

    const toMarkAsDeleted = chats.map((chat) => ({
      ...chat,
      deletionState: ChatDeletionState.DELETED,
    }));
    dispatch(setChatsToDelete(toMarkAsDeleted));
    const mostRecentChat = chats[chats.length - 1];
    if (mostRecentChat && mostRecentChat.chatId === id) navigate('/');
  };

  const undoDeleteChat = (chatId: string) => {
    const newChatsToDelete = [...chatsToDelete.filter((chat) => chat.chatId !== chatId)];
    dispatch(setChatsToDelete(newChatsToDelete)).then(() => {
      showToast({
        message: 'Successfully restored your chat.',
        variant: ToastVariant.SUCCESS,
        onClear: () => triggerDeleteChatMutation(newChatsToDelete),
      });
    });
  };

  const undoDeleteChatIds = (chatId: string[]) => {
    const newChatsToDelete = [...chatsToDelete.filter((chat) => chatId.includes(chat.chatId))];
    dispatch(setChatsToDelete(newChatsToDelete)).then(() => {
      showToast({
        message: `Successfully restored your ${chatId.length} chat${chatId.length === 1 ? '' : 's'}.`,
        variant: ToastVariant.SUCCESS,
        onClear: () => triggerDeleteChatMutation(newChatsToDelete),
      });
    });
  };

  const deleteChat = (ev: MouseEvent<HTMLButtonElement>, chatId: string) => {
    ev.preventDefault();

    dispatch(
      setChatsToDelete([...chatsToDelete, { chatId, deletionState: ChatDeletionState.DELETING }]),
    ).then((dispatchResult) => {
      const chatsToDeleteNew = dispatchResult.payload.chatsToDelete;
      showToast({
        message: 'Chat deletion in progress...',
        variant: ToastVariant.LOADING,
        duration: 5000,
        toastAction: {
          label: 'Undo',
          handleClick: () => undoDeleteChat(chatId),
        },
        onClear: () => {
          triggerDeleteChatMutation(chatsToDeleteNew);
        },
      });
    });
  };

  const deleteChatIds = (chatIds: string[]) => {
    dispatch(
      setChatsToDelete([
        ...chatsToDelete,
        ...chatIds.map((chatId) => {
          return {
            chatId,
            deletionState: ChatDeletionState.DELETING,
          };
        }),
      ]),
    ).then((dispatchResult) => {
      const chatsToDeleteNew = dispatchResult.payload.chatsToDelete;
      showToast({
        message: `Deleting ${chatIds.length} chat${chatIds.length === 1 ? '' : 's'} in progress...`,
        variant: ToastVariant.LOADING,
        duration: 5000,
        toastAction: {
          label: 'Undo',
          handleClick: () => undoDeleteChatIds(chatIds),
        },
        onClear: () => {
          triggerDeleteChatMutation(chatsToDeleteNew);
        },
      });
    });
  };

  return { deleteChat, deleteChatIds, isDeletingChat };
}
