'use client';
import {
  AddDueDilgenceMetaDataDocument,
  DueDiligence,
  Reference,
  SortOrder,
} from '@/@generated/graphql';
import { deleteSearchParams } from '@/helpers/deleteSearchParams';
import { mapReadUrlForReferences, parseContentIdFromInternalUrl } from '@/helpers/references';
import useChatPdfPreview from '@/hooks/useChatPdfPreview';
import { useAddDueDiligenceMetaData, useContentByIdQuery, useMessagesQuery } from '@/lib/swr/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import { updateEditingCell } from '@/store/slices/dueDiligence';
import { ButtonIcon, ButtonVariant, Textarea } from '@unique/component-library';
import { IconClose, IconDownloadLine, IconFileAdd, IconPaperPlane } from '@unique/icons';
import { FolderPathSelection, ToastVariant, useToast } from '@unique/shared-library';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DueDiligenceAddQuestionButton from './DueDiligenceAddQuestionButton';
import DueDiligenceInputUploadButton from './DueDiligenceInputUploadButton';

type Props = {
  getTableData: (fileName: string) => void;
  stopEditingCell: () => void;
  dueDiligenceData: Partial<DueDiligence>;
  sheetName: string;
  chatId: string;
};

export default function DueDiligenceButtons({
  getTableData,
  stopEditingCell,
  dueDiligenceData,
  sheetName,
  chatId,
}: Props) {
  const dueDiligence = useAppSelector((state) => state.dueDiligence);
  const dispatch = useAppDispatch();
  const [contentId, setContentId] = useState('');

  const { showToast } = useToast();

  const [foundReferenceState, setFoundReferenceState] = useState<Reference | null>(null);

  const [searchParams] = useSearchParams();

  const reference = searchParams.get('reference');
  const requestId = searchParams.get('requestId');

  const { handleClickReference, handleClosePdfPreview } = useChatPdfPreview({
    chatId,
    showPdfHighlighting: true,
    redirectInternalStorageOnly: true,
    onClose: () => {
      searchParams.delete('reference');
      deleteSearchParams(['reference', 'requestId']);
    },
  });

  const chatQueryVariables = {
    chatId,
    orderBy: [{ createdAt: SortOrder.Asc }],
  };

  const { data: messages } = useMessagesQuery(chatId ? chatQueryVariables : null, {
    revalidateOnFocus: false,
  });

  const { data: contentData } = useContentByIdQuery(
    !contentId
      ? null
      : {
          contentIds: [contentId],
          chatId,
        },
  );

  useEffect(() => {
    if (!reference) return;
    const foundMessage = messages?.messages?.find((message) =>
      message?.references?.some((ref) => ref.sequenceNumber === Number(reference)),
    );

    const foundReference = foundMessage?.references.find(
      (ref) => ref.sequenceNumber === Number(reference),
    );

    if (foundReference) {
      setContentId(parseContentIdFromInternalUrl(foundReference.url));
      setFoundReferenceState(foundReference as Reference);
    }
  }, [reference, foundReferenceState, messages, requestId]);

  useEffect(() => {
    if (!contentData || !contentId) return;
    handleClickReference(mapReadUrlForReferences([foundReferenceState], contentData, false)[0]);
  }, [contentData, contentId, foundReferenceState, requestId]);

  useEffect(() => {
    return () => {
      handleClosePdfPreview();
    };
  }, []);

  const [questionContentId, setQuestionContentId] = useState(['']);

  const questionFileIds = useMemo(() => {
    if (questionContentId) return questionContentId;
    return dueDiligenceData?.questionFileIds;
  }, [dueDiligenceData?.questionFileIds, questionContentId]);

  const [showQuestionsSelect, setShowQuestionsSelect] = useState(false);

  const [isImportingQuestions, setIsImportingQuestions] = useState(false);

  const { trigger: addDDMetaData } = useAddDueDiligenceMetaData({
    variables: {},
    query: AddDueDilgenceMetaDataDocument,
  });

  const [showFolderPathSelectionModal, setShowFolderPathSelectionModal] = useState(false);

  const clearEditingSelection = () => {
    stopEditingCell();
    dispatch(updateEditingCell(null));
  };

  const classNames = cn({
    relative: true,
    'pl-24': dueDiligence.editingCell,
  });

  const handleSubmitQuestion = (question: string) => {
    addDDMetaData(
      {
        dueDiligenceId: dueDiligenceData?.id,
        questionTexts: [question],
      },
      {
        onSuccess: () => {
          showToast({
            message: 'Question added',
            variant: ToastVariant.SUCCESS,
          });
        },
        onError: () => {
          showToast({
            message: 'Could not add question',
            variant: ToastVariant.ERROR,
          });
        },
      },
    );

    setShowQuestionsSelect(false);
  };

  const handleFolderPathSelected = ({ fileIds }) => {
    setShowFolderPathSelectionModal(false);
    addDDMetaData(
      {
        dueDiligenceId: dueDiligenceData?.id,
        ...(isImportingQuestions ? { questionFileIds: fileIds[0] } : { sourceFileIds: fileIds[0] }),
      },
      {
        onSuccess: () => {
          setQuestionContentId(fileIds[0]);
          showToast({
            message: isImportingQuestions ? 'Questions imported' : 'Files added',
            variant: ToastVariant.SUCCESS,
          });
        },
        onError: () => {
          showToast({
            message: isImportingQuestions ? 'Could not import questions' : 'Could not add files',
            variant: ToastVariant.ERROR,
          });
        },
      },
    );
  };

  return (
    <div className="mx-5 my-5 max-w-3xl">
      <form className="w-full">
        <div className="relative flex w-full justify-between">
          {dueDiligence.editingCell && (
            <button
              className="bg-background text-on-background-dimmed shadow-background absolute left-2 z-10 my-auto mt-1 flex h-5 w-auto items-center justify-center rounded-md px-2 py-4 text-sm font-bold shadow-sm"
              onClick={clearEditingSelection}
            >
              Cell&nbsp;{`${dueDiligence.editingCell?.columnId}${dueDiligence?.editingCell.rowId}`}
              &nbsp;
              <IconClose />
            </button>
          )}
          <Textarea name="text" placeholder="Ask your table anything" className={classNames} />
          <ButtonIcon
            variant={ButtonVariant.PRIMARY}
            className="absolute right-9 top-0 my-auto mb-2.5 h-8 w-8 border-none"
            icon={<IconPaperPlane />}
          />
        </div>
      </form>
      <div className="mt-3 flex flex-wrap gap-3 md:flex-nowrap">
        <ButtonIcon
          variant={ButtonVariant.SECONDARY}
          icon={<IconFileAdd />}
          onClick={() => {
            setIsImportingQuestions(false);
            setShowFolderPathSelectionModal(true);
          }}
          disabled={!questionFileIds}
          className="text-nowrap"
        >
          Add Files
        </ButtonIcon>
        <DueDiligenceAddQuestionButton
          setShowQuestionsSelect={setShowQuestionsSelect}
          showQuestionsSelect={showQuestionsSelect}
          questionFileIds={questionFileIds}
          handleSubmitQuestion={handleSubmitQuestion}
        />
        {!showQuestionsSelect && (
          <>
            <DueDiligenceInputUploadButton
              handleOpenKnowledgeBaseModal={() => {
                setShowFolderPathSelectionModal(true);
                setIsImportingQuestions(true);
              }}
              setQuestionFileIds={setQuestionContentId}
              dueDiligenceId={dueDiligenceData?.id}
            />
            <ButtonIcon
              onClick={() => {
                getTableData(sheetName);
              }}
              variant={ButtonVariant.SECONDARY}
              icon={<IconDownloadLine />}
            >
              Download
            </ButtonIcon>
          </>
        )}
      </div>
      {showFolderPathSelectionModal && (
        <FolderPathSelection
          handleClose={() => setShowFolderPathSelectionModal(false)}
          handleSubmit={handleFolderPathSelected}
          submitButtonText="Add files"
          isSelectable
          modalTitle="Select Files or Folders to add"
          maxItemsToSelect={isImportingQuestions ? 1 : undefined}
        />
      )}
    </div>
  );
}
