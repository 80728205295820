const isFileName = (str: string) => /^[^<>"/\\|?*\n]+(\.[a-zA-Z0-9]{2,4})$/.test(str);

export const getFileTypeForPrompt = (fileName: string): string | null => {
  // If file name is not valid, return null
  if (!fileName || !isFileName(fileName)) {
    return null;
  }

  // Split the file name to get the file type
  const fileNameParts = fileName.split('.');
  if (fileNameParts.length < 2) {
    return null;
  }

  const fileType = fileNameParts[fileNameParts.length - 1];
  return fileType;
};
