import { CustomToolPanelProps } from 'ag-grid-react';
import { IconClose, IconProfile } from '@unique/icons';
import SenderProfile from '../Chat/SenderProfile';
import Badge from '@unique/component-library/src/Badge';
import { useMagicTableCellLogEntry } from '@/lib/swr/hooks';
import { setSelectedContextCell, useAppDispatch, useAppSelector } from '@/store';
import { useParams } from 'react-router-dom';
import Skeleton from '@unique/component-library/src/Skeleton';
import { MagicTableActorType, MagicTableLogEntry } from '@/@generated/graphql';
import { format } from 'date-fns';

export interface CellHistorySidebarProps extends CustomToolPanelProps {
  title: string;
}

const sidebarWidth = '500px';

export default (props: CellHistorySidebarProps) => {
  const selectedContextCell = useAppSelector((state) => state.dueDiligence?.selectedContextCell);
  const dispatch = useAppDispatch();

  const params = useParams();

  const closeSidebar = () => {
    props.api.closeToolPanel();
    props.api.setSideBarVisible(false);
    dispatch(setSelectedContextCell(null));
  };

  const { data: logEntry, isLoading } = useMagicTableCellLogEntry({
    rowOrder: Number(selectedContextCell?.rowOrder),
    columnOrder: selectedContextCell?.columnOrder,
    sheetId: params?.spaceSlug,
  });

  const cellLogEntries = logEntry?.getMagicTableCellLogEntry;

  return (
    <div className={`w-[${sidebarWidth}]`}>
      <div className="flex w-full justify-end pr-2">
        <button className="my-3" onClick={closeSidebar}>
          <IconClose />
        </button>
      </div>
      <div className="flex flex-col px-5">
        <div className="flex flex-col gap-2">
          <p className="text-on-surface text-2xl font-extrabold">Information History</p>
          <p className="text-on-background-dimmed font-medium">
            {selectedContextCell?.columnId && selectedContextCell?.rowId
              ? `Cell ${selectedContextCell.columnId}${selectedContextCell.rowId}`
              : 'Cell Empty'}
          </p>
        </div>
        <div className="mt-5 flex flex-col gap-8">
          {isLoading ? (
            <CellHistorySidebarSkeleton />
          ) : (
            <>
              {cellLogEntries?.length > 0 ? (
                cellLogEntries?.map((entry, index) => {
                  return <Interaction entry={entry} key={index} />;
                })
              ) : (
                <p className="text-on-background-dimmed text-sm">No history found for this cell.</p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Interaction = ({ entry }: { entry: Partial<MagicTableLogEntry> }) => {
  const isUser = entry?.actorType === MagicTableActorType.User;
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <span className="border-on-background-dimmed h-4 w-1 border-2"></span>
        <p className="text-on-background-dimmed text-xs font-semibold uppercase">
          {format(new Date(entry?.createdAt), "MMM d, yyyy 'at' HH:mm")}
        </p>
      </div>
      <div className="mt-3 flex gap-3">
        <div className="flex-1">
          <SenderProfile isUserMessage={isUser} />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center justify-between gap-5">
            <p className="text-on-surface text-nowrap text-2xl font-bold">
              {isUser ? 'User edited this Cell' : 'AI Agent updated this cell'}
            </p>
            {isUser && (
              <Badge classes="px-2 !border-1 !border !border-attention-variant py-3 flex items-center rounded-lg !bg-background !text-attention-variant text-nowrap">
                <IconProfile />
                &nbsp;&nbsp;Human-in-the-loop
              </Badge>
            )}
          </div>
          <div className="mt-2 flex flex-col gap-2">
            <p className="text-on-background-dimmed font-semibold uppercase">Updated Question:</p>
            <p className="text-on-background-main leading-5">{entry?.text}</p>
            {/* Use this when the updated answer is included in the payload */}
            {/* <p className="text-on-background-dimmed font-semibold uppercase">Updated Answer:</p>
            <p className="text-on-background-main leading-5"></p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const CellHistorySidebarSkeleton = () => (
  <>
    {Array(5)
      .fill('')
      .map((_, i) => (
        <div key={i} className="flex flex-col gap-5">
          <Skeleton className="!bg-control !h-[35px] !w-[450px]" />
          <Skeleton className="!bg-control !h-[18px] !w-[450px]" />
          <Skeleton className="!bg-control !h-[18px] !w-[300px]" />
          <Skeleton className="!bg-control !h-[18px] !w-[450px]" />
          <Skeleton className="!bg-control !h-[18px] !w-[450px]" />
        </div>
      ))}
  </>
);
