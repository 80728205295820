import { FILE_TYPES } from '@unique/shared-library';
import { Accept } from 'react-dropzone/.';

const validateAndMapFileTypes = (fileTypes: string) => {
  // map of mime type to file type array
  const inAcceptFormat: Accept = {};
  fileTypes.split(',').forEach((fileType) => {
    const fileTypeFormatted = fileType.replaceAll(' ', '').toLowerCase();
    if (FILE_TYPES[fileTypeFormatted]) {
      FILE_TYPES[fileTypeFormatted].forEach((mimeType) => {
        inAcceptFormat[mimeType] = [`.${fileTypeFormatted}`];
      });
    }
  });
  return inAcceptFormat;
};

export const mapFileTypesStringToAcceptType = (fileTypes: string, fallbackTypes: string) => {
  if (!fileTypes) {
    return validateAndMapFileTypes(fallbackTypes);
  }
  const filteredFileTypes = validateAndMapFileTypes(fileTypes);
  // in case all file types are invalid, return the fallback types
  if (Object.keys(filteredFileTypes).length === 0) {
    return validateAndMapFileTypes(fallbackTypes);
  }
  return filteredFileTypes;
};
