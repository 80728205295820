import { getFileTypeForPrompt } from '@/helpers/getFileTypeForPrompt';
import { ButtonIcon, ButtonVariant, FileTypeIcon } from '@unique/component-library';
import { IconExpiredFile } from '@unique/icons';
import { FC } from 'react';

export const PROMPT_URL_PREFIX_HTTPS = 'https://prompt=';
export const PROMPT_URL_PREFIX_HTTP = 'http://prompt=';

const extractPrompt = (href: unknown) => {
  if (typeof href !== 'string') return '';
  const prompt = href.replace(PROMPT_URL_PREFIX_HTTPS, '').replace(PROMPT_URL_PREFIX_HTTP, '');
  return decodeURIComponent(prompt);
};

type ChatPromptButtonProps = {
  handleSelectPrompt?: (prompt: string) => void;
  href?: unknown;
  promptCta: string;
  isExpired?: boolean;
};

export const ChatPromptButton: FC<ChatPromptButtonProps> = ({
  handleSelectPrompt,
  href,
  promptCta,
  isExpired,
}) => {
  const fileType = getFileTypeForPrompt(promptCta);
  const prompt = extractPrompt(href);

  const getIcon = () => {
    if (isExpired) {
      return <IconExpiredFile width="18px" height="18px" />;
    }
    return fileType ? <FileTypeIcon fileType={fileType} dimensions="18px" /> : null;
  };
  return (
    <ButtonIcon
      variant={ButtonVariant.SECONDARY}
      onClick={() => handleSelectPrompt && handleSelectPrompt(prompt)}
      icon={getIcon()}
      className={isExpired ? 'pointer-events-none' : ''}
    >
      {promptCta}
    </ButtonIcon>
  );
};
