import { ContentByIdQuery, getSdk, Reference } from '@/@generated/graphql';
import PDFPreview from '@/components/Chat/PDFPreview';
import { isInternalUniqueUrl, parseContentIdFromInternalUrl } from '@/helpers/references';
import { loadFile } from '@unique/next-commons/helpers';
import { logger } from '@unique/next-commons/logger';
import { ClientContext, Service } from '@unique/next-commons/swr';
import { LayoutContext, useToast } from '@unique/shared-library';
import React, { useContext, useState } from 'react';
import { useAuth } from 'react-oidc-context';

type Props = {
  chatId: string;
  showPdfHighlighting?: boolean;
  redirectInternalStorageOnly?: boolean;
  onClose?: () => void;
};

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:ChatReference',
});

export default function useChatPdfPreview({
  chatId,
  showPdfHighlighting,
  redirectInternalStorageOnly,
  onClose,
}: Props) {
  const auth = useAuth();

  const { setIsMenuExpanded, setSplitPaneContent } = useContext(LayoutContext);
  const { clients, services } = useContext(ClientContext);

  const [loadingReference, setLoadingReference] = useState<string | null>(null);

  const { showErrorToast } = useToast();

  const handleClosePdfPreview = () => {
    setSplitPaneContent(null);
    onClose?.();
  };

  const handleOpenReferenceUrl = async (reference: Reference) => {
    if (!isInternalUniqueUrl(reference.url)) {
      window.open(reference.url, '_blank', 'noopener noreferrer');
      return;
    }

    // Set reference as loading to show spinner in template
    setLoadingReference(reference.id);
    try {
      // Request content object
      const sdk = getSdk(clients[Service.NODE_INGESTION]);
      const query: ContentByIdQuery = await sdk.ContentById({
        contentIds: parseContentIdFromInternalUrl(reference.url),
        chatId,
      });
      const content = query.contentById[0];

      if (redirectInternalStorageOnly || !content.url) {
        try {
          await loadFile({
            accessToken: auth.user.access_token,
            ingestionUrl: services[Service.NODE_INGESTION],
            content,
            chatId,
            shouldOpen: true,
          });
        } catch {
          showErrorToast(`Can not open file ${content.title || content.key}`);
        }
      } else {
        window.open(content.url, '_blank', 'noopener noreferrer');
      }
    } catch (error) {
      showErrorToast(`Reference ${reference.name} can't be opened.`, 6000);
      log.error(error);
    } finally {
      setLoadingReference(null);
    }
  };

  const handleClickReference = (reference) => {
    // Only show PDF preview for internal files (e.g. files that have a content entry).
    // For external files (e.g. Sharepoint), CORS is preventing us from showing the preview
    if ((!!reference.pdfPreviewName || !!reference.pdfPreviewUrl) && showPdfHighlighting) {
      setSplitPaneContent(
        <PDFPreview
          key={reference.id}
          reference={reference}
          chatId={chatId}
          handleOpenReferenceUrl={handleOpenReferenceUrl}
          handleClosePdfPreview={handleClosePdfPreview}
        />,
      );
      setIsMenuExpanded(false);
      return;
    }
    handleOpenReferenceUrl(reference);
  };
  return { handleClickReference, loadingReference, handleClosePdfPreview } as const;
}
