'use client';
import {
  getUseUpdateMagicTableSheetName,
  useContentByChatQuery,
  usePaginatedContentQuery,
  useUpdateMagicTableSheetName,
} from '@/lib/swr/hooks';
import { ButtonIcon, ButtonVariant, InputField, Spinner } from '@unique/component-library';
import { FC, useMemo, useState } from 'react';
import FileAccordion from './FileAccordion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { updateDueDiligencePayload, useAppDispatch, useAppSelector } from '@/store';

type Props = {
  assistantId: string;
};

const CreateDueDiligenceBox: FC<Props> = ({ assistantId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dueDiligencePayload = useAppSelector((state) => state.dueDiligence.dueDiligencePayload);
  const sourceContentIds = dueDiligencePayload?.sourceContentIds || [];
  const importStepContentId = dueDiligencePayload?.importStepContentId || null;
  const [fileName, setFileName] = useState<string>('Untitled Sheet');
  const [searchParams] = useSearchParams();

  const chatId = searchParams.get('chatId');
  const dueDiligenceId = searchParams.get('dueDiligenceId');
  const sheetId = searchParams.get('sheetId');

  const { data: chatContent, isLoading: isLoadingChatContent } = useContentByChatQuery({
    chatId,
  });

  const { data: sourceContent, isLoading: isLoadingSourceContent } = usePaginatedContentQuery(
    { where: { id: { in: sourceContentIds } } },
    { revalidateOnFocus: false },
  );

  const { data: importStepContent, isLoading: isLoadingImportStepContent } =
    usePaginatedContentQuery(
      importStepContentId ? { where: { id: { equals: importStepContentId } } } : null,
      { revalidateOnFocus: false },
    );

  const handleCreateTable = async () => {
    if (chatId && dueDiligenceId && sheetId) {
      dispatch(
        updateDueDiligencePayload({
          importStepContentId: dueDiligencePayload.importStepContentId,
          sheetId,
          sheetName: fileName,
          sourceContentIds: dueDiligencePayload.sourceContentIds,
          dueDiligenceId,
        }),
      );
      navigate(`/space/${assistantId}/${sheetId}`);
    }
  };

  const { trigger: updateSheetName, isMutating: updatingSheetName } = useUpdateMagicTableSheetName(
    getUseUpdateMagicTableSheetName(),
    {
      onSuccess: () => {
        handleCreateTable();
      },
    },
  );

  const questionsContent = useMemo(() => {
    if (importStepContent?.paginatedContent?.nodes?.length) {
      return importStepContent?.paginatedContent?.nodes;
    }
    return chatContent?.contentByChat && chatContent?.contentByChat?.length > 0
      ? chatContent?.contentByChat
      : [];
  }, [importStepContent, chatContent?.contentByChat]);

  const isCreateButtonDisabled = updatingSheetName || !fileName || !sheetId;

  const numOfSources = sourceContent?.paginatedContent?.nodes?.length || 0;
  return (
    <div className="mx-auto mt-10 flex max-w-7xl justify-center px-5">
      <div className="flex w-full max-w-3xl">
        <div className="flex w-full flex-col gap-5">
          <div className="flex w-full gap-5">
            <NumberBox digit={1} />
            <FileAccordion
              buttonTitle="Questions"
              content={questionsContent}
              isLoading={isLoadingChatContent || isLoadingImportStepContent}
            />
          </div>
          <div className="flex w-full gap-5">
            <NumberBox digit={2} />
            <FileAccordion
              buttonTitle={`${numOfSources} Source${numOfSources > 1 ? 's' : ''}`}
              content={sourceContent?.paginatedContent?.nodes}
              isLoading={isLoadingSourceContent}
            />
          </div>
          <div className="flex w-full gap-2">
            <div className="flex items-start gap-5">
              <NumberBox digit={3} />
              <div className="flex flex-col gap-2">
                <p className="text-on-background-main font-extrabold">Sheet Name</p>
                <div className="w-full sm:w-[500px] md:w-[710px]">
                  <InputField
                    autoFocus
                    name="fileName"
                    classes="w-full"
                    value={fileName}
                    handleChange={(e) => setFileName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 flex w-full justify-end">
            <ButtonIcon
              variant={ButtonVariant.PRIMARY}
              onClick={() => updateSheetName({ sheetId, name: fileName })}
              icon={updatingSheetName ? <Spinner size={16} wrapperClasses="pt-1" /> : null}
              disabled={isCreateButtonDisabled}
            >
              Create Table
            </ButtonIcon>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDueDiligenceBox;

const NumberBox = ({ digit }: { digit: number }) => {
  return (
    <div className="bg-primary-cta text-on-primary flex h-10 w-10 items-center justify-center rounded-md font-extrabold">
      {digit}
    </div>
  );
};
