import {
  useMagicTableQuery
} from '@/lib/swr/hooks';
import { useAppSelector } from '@/store';
import { logger } from '@unique/next-commons/logger';
import { useEffect, useState } from 'react';

const log = logger.child({
  package: 'chat',
  namespace: 'components:due-diligence-component:useDueDiligenceQuery',
});

type Props = { sheetId: string; setIsMenuExpanded: (isMenuExpanded: boolean) => void };

export default function useDueDiligenceQuery({ sheetId, setIsMenuExpanded }: Props) {
  const dueDiligencePayload = useAppSelector((state) => state.dueDiligence.dueDiligencePayload);
  const isGridReady = useAppSelector((state) => state.dueDiligence.isGridReady);
  const [queryPaused, setQueryPaused] = useState(true);

  const requeryDueDiligence = () => {
    mutate();
  };

  useEffect(() => {
    if (!isGridReady) return;
    setIsMenuExpanded(false);
    setQueryPaused(false);
  }, [isGridReady, dueDiligencePayload?.sheetId, sheetId, queryPaused]);

  const {
    data,
    isLoading: loadingTableData,
    mutate,
  } = useMagicTableQuery(
    { cursor: { id: queryPaused ? null : sheetId } },
    {
      onError: () => {
        log.error('Unable to fetch Magic Table Data');
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    },
  );

  const dueDiligenceData = data?.getTransformedMagicTableSheet?.magicTableSheet;

  return { dueDiligenceData, loadingTableData, requeryDueDiligence } as const;
}
